.box {
  border-radius: 10px;
  background-image: url("../../images/img_99.jpg");
  margin: 30px auto;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}

.infoText {
  position: relative;
  color: white;
  z-index: 3;
  list-style-type: none;
  padding: 2%;
  text-align: left;
}

.infoTitle {
  font-size: x-large;
  line-height: normal;
  font-weight: 600;
  text-align: center;
  margin: 20px auto;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
}

.infoText li {
  display: flex;
}

.text {
  font-size: large;
  line-height: normal;
}

.listIcon {
  margin: 5px 10px;
  font-size: xx-large;
  transition: all 0.5s;
}

.listVisible li span {
  animation: paint 0.8s ease forwards;
}
.listVisible .infoTitle {
  animation: paintShadow 2s ease forwards;
}

.listVisible li:nth-of-type(1) span {
  animation-delay: 0.5s;
}

.listVisible li:nth-of-type(2) span {
  animation-delay: 1.5s;
}

.listVisible li:nth-of-type(3) span {
  animation-delay: 2.5s;
}

.listVisible li:nth-of-type(4) span {
  animation-delay: 3.5s;
}

@keyframes paint {
  from {
    color: white;
  }
  to {
    color: #2dbdb8;
  }
}

@keyframes paintShadow {
  from {
    text-shadow: 3px 3px 16px #2dbdb8;
    color: white;
  }
  to {
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
    color: #2dbdb8;
  }
}
@media screen and (min-width: 600px) {
  .infoTitle {
    margin: 40px auto;
  }
  ul {
    width: 75%;
    margin: auto;
  }
}

@media screen and (min-width: 992px) {
  .infoTitle {
    font-size: 40px;
    margin-top: 10px;
    line-height: 46px;
  }
  .text {
    font-size: x-large;
    line-height: normal;
  }
  ul {
    width: 95%;
    margin: auto;
  }
}
