.infoBox {
  margin: 0 auto 30px;
  padding: 20px 10px 20px;
  background-image: url("../../images/minecraft_img.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.image {
  width: 50%;
  margin: 10px auto;
  animation: scaleAnimation 15s infinite;
}

.infoTitle {
  font-size: xx-large;
  font-weight: 600;
  font-family: Impact, fantasy;
  text-shadow: -1px -1px 0 black, 1px -1px 3px black, -1px 1px 3px black,
    1px 1px 6px black;
  color: rgba(22, 197, 6);
  transform: perspective(500px) rotateX(30deg);
  transform-origin: center;
  margin-bottom: 0;
}

.infoTitle span {
  display: inline-block;
  opacity: 0;
  margin-left: 20px;
  transform: translateX(0);
}

.infoTitle span:first-child {
  transform: translateX(-100%);
}

.infoTitle span:last-child {
  transform: translateX(100%);
}

.infoTitleVisible span:first-child {
  animation: slideInLeft 1s ease-out forwards;
  animation-delay: 1s;
}

.infoTitleVisible span:last-child {
  animation: slideInRight 1s ease-out forwards;
  animation-delay: 1s;
}

.infoText {
  font-size: large;
  font-weight: 600;
  line-height: normal;
  margin: auto;
  text-align: start;
  padding-left: 10%;
  color: #08040f;
  animation: slideInLeft 1.5s ease-out 1.5s forwards;
  opacity: 0;
}

.img {
  width: 50%;
  margin: 0 auto 10px;
  padding-left: 10px;
}

@keyframes scaleAnimation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (min-width: 992px) {
  .infoBox {
    padding: 50px 10px 30px;
  }
  .infoTitle {
    font-size: 70px;
  }
  .infoText {
    font-size: x-large;
  }
  .img {
    margin: 10px auto;
  }
}

@media screen and (min-width: 1200px) {
  .infoText {
    font-size: xx-large;
    padding: 2%;
  }
}
