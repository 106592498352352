.scrollToTop {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  width: 55px;
  height: 55px;
  background-color: rgb(2, 131, 145, 0.5);
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
  z-index: 2;
}

@media (max-width: 768px) {
  .scrollToTop {
    width: 44px;
    height: 44px;
  }
}

.scrollToTop.visible {
  opacity: 1;
  visibility: visible;
}

.scrollToTop.visible:hover,
.scrollToTop.visible:active {
  background-color: rgb(2, 131, 145, 0.7);
}
