.bgHeader {
  background: linear-gradient(to bottom, #2dbdb8, #028391);
  height: 100px;
  padding: 1%;
  position: relative;
}

.imglogo {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 90px;
  height: 90px;
}

.rating {
  margin-left: 25%;
}

.ratingBox {
  display: flex;
  justify-content: center;
  margin-bottom: -10px;
}

.phone {
  color: white;
  font-size: large;
  cursor: pointer;
  transition: all 0.5s;
  margin-top: 5px;
}
.phone:hover,
.phone:active {
  text-decoration: underline;
}

@media screen and (min-width: 600px) {
  .bgHeader {
    height: 120px;
    padding: 2% 2% 3%;
  }
  .imglogo {
    top: 10px;
    left: 20px;
    width: 100px;
    height: 100px;
  }
}

@media screen and (min-width: 992px) {
  .bgHeader {
    height: 140px;
    padding: 10px 5%;
  }
  .imglogo {
    top: 10px;
    width: 120px;
    height: 120px;
  }

  .rating {
    margin-left: 30%;
  }
  .phone {
    color: white;
    font-size: large;
    cursor: pointer;
    transition: all 0.5s;
    margin-top: 20px;
  }
}
