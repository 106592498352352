.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  padding: 10px;
  overflow: hidden;
}
.modalContent {
 
  padding: 50px 10px 20px;
  border-radius: 5px;
  width: 370px;
  position: relative;
}
.modalClose {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  transition: all 500ms;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0 5px 5px;
}
.modalClose:hover {
  background-color: #F5F5F5;
  border-radius: 5px;
}
