.bgFooter {
  background: linear-gradient(to bottom, #2dbdb8, #08040f);
  padding: 5% 10% 1%;
  color: white;
  height: auto;
}
.icons {
  font-size: 30px;
}
.iconContactsBox {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid #08040f;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-left: 10px;
  margin-right: 10px;
}

.iconContacts:hover,
.iconContacts:active {
  transform: scale(1.1);
  transition: all 0.5s;
}

.text {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: large;
}

.contacts {
  cursor: pointer;
  margin-left: 10px;
  transition: all 0.5s;
  background-color: transparent;
  border: none;
  color: white;
}

.contacts:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .icons {
    font-size: 50px;
  }
  .text {
    margin-bottom: 0;
    margin-left: 10px;
    font-size: larger;
  }
}
@media screen and (max-width: 600px) {
  .bgFooter {
    padding: 5% 2% 1%;
  }
}
