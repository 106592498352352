.form {
  min-width: 300px;
  max-width: 400px;
  width: 100%;
  margin: 5% auto 5px;
  background-color: transparent;
}

.label {
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
  text-align: left;
  line-height: 18px;
}
