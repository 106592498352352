.reviewsTitle {
  font-size: xx-large;
  font-weight: 500;
  color: #2dbdb8;
  font-family: Impact, fantasy;
  text-shadow: -1px -1px 0 black, 1px -1px 3px black, -1px 1px 3px black,
    1px 1px 6px black;
}

.reviewBox {
  border: 1px solid black;
  border-radius: 7px;
  padding: 0;
  margin: 10px;
  min-height: 150px;
  background-color: white;
  position: relative;
}

.reviewText {
  text-align: left;
  line-height: normal;
  padding: 10px 10px 40px;
  margin-left: 10px;
}

.date {
  position: absolute;
  bottom: 5px;
  left: 38%;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .reviewsTitle {
    font-size: 70px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.fadeIn {
  animation: fadeIn 0.3s ease-in;
}

.fadeOut {
  animation: fadeOut 0.3s ease-out;
}
