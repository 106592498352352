.header {
  margin: 20px auto;
  font-weight: 600;
  font-size: xx-large;
}
.title {
  margin-top: 15px;
  font-weight: 600;
  font-size: larger;
  position: relative;
}

.price {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: x-large;
  font-weight: 600;
  color: #333;
}

.price::before,
.price::after {
  content: "";
  flex: 1;
  height: 2px;
  background-color: rgba(22, 197, 6);
  margin: 0 10px;
}

.iconPrice {
  font-size: xx-large;
  margin-right: 10px;
}
.iconPriceSale {
  font-size: xx-large;
  margin-right: 10px;
  color: red;
}

.priceSale {
  color: red;
  margin-bottom: 0;
}
.priceBeforeSale {
  text-decoration: line-through;
  padding-left: 25px;
  margin-bottom: 0;
  margin-top: -10px;
}

.sale {
  width: 60px;
  height: 50px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  position: absolute;
  top: 30px;
  left: 10px;
}

.buyBtn {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: larger;
  padding: 10px 20px;
  color: white;
  background: linear-gradient(
    0deg,
    rgba(20, 167, 62, 1) 0%,
    rgba(102, 247, 113, 1) 100%
  );
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #14a73e98;
  letter-spacing: 0.05em;
  border-radius: 20em;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: all 0.5s;
  line-height: normal;
}

.buyBtn:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #14a73e98;
  font-weight: 600;
}

.buyBtn:active {
  box-shadow: 0 0.3em 1em -0.5em #14a73e98;
  font-weight: 600;
}
