.boxCall {
  position: fixed;
  top: 2rem;
  right: 2rem;
  width: 85px;
  height: 85px;
  font-size: small;
  color: rgb(2, 131, 145);
  background-color: white;
  box-shadow: 0 0 25px rgba(2, 131, 145, 0.8);
  border: none;
  border-radius: 50%;
  display: block;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s;
  z-index: 2;
  line-height: 14px;
  padding-top: 10px;
}
.calling {
  transition: all 0.5s;
}
.calling:hover,
.calling:active {
  text-decoration: underline;
  font-weight: 600;
}

.closeBtn {
  position: absolute;
  top: -25px;
  right: -5px;
  width: 30px;
  height: 20px;
}

.closeBtn:hover,
.closeBtn:active {
  border: 1px solid gray;
  transform: scale(1.2);
  transition: all 0.5s;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.iconAnimation {
  animation: rotateIcon 4s ease-in-out infinite;
  font-size: 30px;
}

@media (max-width: 768px) {
  .boxCall {
    top: auto;
    bottom: 6rem;
    right: 1rem;
    width: 55px;
    height: 55px;
    padding-top: 5px;
    font-size: x-small;
    line-height: 10px;
  }
  .closeBtn {
    top: -20px;
    width: 20px;
    height: 15px;
  }
  .closeBtn:hover,
  .closeBtn:active {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .iconAnimation {
    animation: rotateIcon 4s ease-in-out infinite;
    font-size: medium;
  }
}

@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: scale(1.2);
  }
  15% {
    transform: rotate(-15deg) scale(1.2);
  }
  20% {
    transform: rotate(15deg) scale(1.2);
  }
  25% {
    transform: rotate(-15deg) scale(1.2);
  }
  30% {
    transform: rotate(15deg) scale(1.2);
  }
  40% {
    transform: rotate(0deg) scale(1);
  }

  100% {
    transform: rotate(0deg);
  }
}
